import React from 'react'
import PageContainer from '../components/PageContainer'
import PageTitle from '../components/PageTitle'
import { COLOR_ACCENT } from '../libs/colors'
import BalanceCard from '../components/wallet/BalanceCard'
import Transfer from '../components/wallet/Transfer'
import { useAppContext } from '../libs/contextLib'
import Bot from '../components/wallet/Bot'

export default function Wallet() {

  const { connectedAddress: address, notify, login } = useAppContext()

  return (
    <PageContainer>
      <PageTitle>Wallet</PageTitle>

      <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center'}}>

        <span style={{ fontSize: '1.5rem', fontWeight: 600, color: COLOR_ACCENT, marginBottom: '1rem' }}>Balance</span>
        <BalanceCard account={address} symbol="FAN" />

        <span style={{ fontSize: '1.5rem', fontWeight: 600, color: COLOR_ACCENT, marginBottom: '1rem' }}>Transfer</span>
        <Transfer />

        <span style={{ fontSize: '1.5rem', fontWeight: 600, color: COLOR_ACCENT, marginBottom: '1rem' }}>Discord Bot</span>
        <Bot />

      </div>

    </PageContainer>
  )
}
