import React from 'react'

export default function Tabs({ tabs = [], active, onTabChanged = () => {}, style = {} }) {
  return (
    <div className="tabs is-medium" style={{ ...style }}>
      <ul>
        {
          tabs.map(item => <li key={item} className={'Tab '+ ( item === active ? 'is-active' : '' )}><a onClick={() => onTabChanged(item)} >{item}</a></li>)
        }
      </ul>
    </div>
  )
}
