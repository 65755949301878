import React, { useState } from 'react'

import { useAppContext } from "../libs/contextLib";

import PageContainer from '../components/PageContainer'
import PageTitle from '../components/PageTitle'
import { COLOR_ACCENT, COLOR_PRIMARY } from '../libs/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfoBox from '../components/InfoBox';

function Tile({ service }) {
  const { title, description = '', icon = 'coins', amount } = service
  return (
    <div style={{
      padding: '4rem',
      border: '5px solid '+'white',
      borderRadius: 4,
      background: '#8bd8bd',
      cursor: 'pointer'
    }}>

      <div style={{ fontSize: '2rem', fontWeight: '600', color: COLOR_ACCENT, textAlign: 'center' }}> {title} </div>
      <div style={{
        padding: '2rem',
        display: 'flex',
        justifyContent: 'center'
      }}>
        <FontAwesomeIcon icon={icon} style={{ fontSize: '8rem', color: '#243665' }} />
      </div>
      <div style={{ textAlign: 'center', fontSize: '1.1rem' }}>{description}</div>
      {amount && <div>Requires: amount $FAN</div> }
    </div>
  )
}


function Subtitle({ children, style }) {
  return (
    <h2 className="subtitle" style={{ marginBottom: '.25rem', ...style }}>
      {children}
    </h2>
  )
}

export default function Create() {

  const services = [
    { title: 'Social', description: 'Tokenize your profile or brand' },
    // { title: 'Collectibles', description: 'Create unique tokens for your work.', icon: 'palette' },
    { title: 'Community', description: 'Create your own community tokens', icon: 'users' },
  ]

  return (
    <PageContainer>
      <PageTitle>Create</PageTitle>

    {/*
      <div style={{
        fontSize: '2rem',
        color: COLOR_ACCENT,
        fontWeight: 'bold',
        border: '4px dashed '+'#F9F9F9',
        padding: '2.5rem',
        display:'flex',
        textAlign: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}> 
    */}

    <InfoBox>
      <div style={{
        fontSize: '2rem',
        padding: '2.5rem',
        textAlign: 'center',
      }}> 
        <div>Factory is closed at the moment.</div>
        <div>Check back later</div> 
      </div>
    </InfoBox>

      <div className="columns" style={{ marginTop: '2rem' }}>
        {services.map(s => (
          <div className="column">
            <Tile service={s}/>
          </div>
        ))}
      </div>

    </PageContainer>
  )
}
