import React from 'react'
import { COLOR_ACCENT } from '../libs/colors'

export default function PageTitle({ children }) {
  return (
    <h1 className="title" style={{ 
      marginBottom: '2rem',
      color: COLOR_ACCENT
      // color: 'rgb(216 216 216 / 90%)',
      // fontSize: '3rem'
    }}>
      { children }
    </h1>
  )
}
