import React, { useState } from 'react'
import { debounce, isDev } from '../../libs/utils'
import Button from '../Button'
import Field from '../Field'
import Panel from '../Panel'
import TwitterEmbeddedTweet from '../TwitterEmbeddedTweet'
import { useAppContext } from '../../libs/contextLib'
// import { api } from '721-fans-lib'

const parseTweetId = debounce((tweetUrl, setTweetId) => {
  if (!tweetUrl) {
    return setTweetId(undefined)
  }
  if (tweetUrl.startsWith('dev') && isDev()) {
    return setTweetId(tweetUrl)
  }
  try {
    if (tweetUrl.includes('?')) {
      tweetUrl = tweetUrl.split('?')[0] // remove query params
    }
    const tweetId = tweetUrl.split('/').pop()
    if (tweetId.match(/^\d+$/)) {
      setTweetId(tweetId)
    } else {
      setTweetId(undefined)
    }
  } catch (error) {
    console.log('parser error', error)
    setTweetId(undefined)
  }
}, 1000)

export default function TwitterPostVerification() {
  const [tweetUrl, setTweetUrl] = useState('')
  const [tweetId, setTweetId] = useState(undefined)
  const [isVerifying, setIsVerifying] = useState(false)

  const { setConnectedIdentities, notify, connectedAddress } = useAppContext();

  function handleUrlChanged(newUrl) {
    setTweetUrl(newUrl)
    setTweetId(undefined)
    parseTweetId(newUrl, setTweetId)
  }

  const handleVerify = async () => {
    /*
    setIsVerifying(true)
    try {
      const attestation = await api.verifyTwitterProof(tweetId)
      // console.log('received attestation', attestation)
      if (attestation) {
        if (attestation.isVerified) {
          const { user, channel, address } = attestation
          if (address.toLowerCase() !== connectedAddress.toLowerCase()) {
            throw new Error("not user's address")
          }
          const { username } = user
          // TODO extend existing
          setConnectedIdentities([
            { username, channel, attestation }
          ])
          // this will unmount the component - do not change state afterwards (return)
          return notify('Verification successful!', 'dark')
        } else {
          let errorMessage = ''
          if(attestation && attestation.error) {
            errorMessage = ': ' + attestation.error
          }
          notify('Verification failed' + errorMessage, 'error')
        }
      }
      setIsVerifying(false)
    } catch (error) {
      // FIXME handle error
      notify('Verification failed' + (error && error.message ? `: ${error.message}` : '') , 'error')
      console.log('verification error', error)
      setIsVerifying(false)
    } finally {
    }
    */
  }

  return (
    <Panel>
      <Field placeholder={"Paste link to your proof tweet (previous step)"} value={tweetUrl} onChange={e => handleUrlChanged(e.target.value)} />
      <div>
        {tweetId && /* !isDev() && */ <TwitterEmbeddedTweet tweetId={tweetId} />}
      </div>
      <Button loading={isVerifying} onClick={handleVerify} enabled={!!tweetId}>Verify</Button>
    </Panel>
  )
}
