import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import Button from '../components/Button'
import EthereumAddress from '../components/EthereumAddress'
import LoadingView from '../components/LoadingView'
import PageContainer from '../components/PageContainer'
import PageTitle from '../components/PageTitle'
import Tabs from '../components/Tabs'
import { COLOR_ACCENT, COLOR_ERROR, COLOR_PRIMARY } from '../libs/colors'
import { useAppContext } from '../libs/contextLib'
import ProofOfKeys from '../components/proof/ProofOfKeys';
import TweetProof from '../components/proof/TweetProof';
import TwitterPostVerification from '../components/proof/TwitterPostVerification';
import { useRouteMatch } from 'react-router-dom'
import VerifiedIcon from '../components/VerifiedIcon'
import InfoBox from '../components/InfoBox'
import { verifier } from '721-fans-lib'

function Subtitle({ children, style }) {
  return (
    <h2 className="subtitle" style={{ marginBottom: '.25rem', ...style }}>
      {children}
    </h2>
  )
}

function StepConnectEthereum({ isActive, address, handleConnect }) {
  return (
    <section className={`Step ${isActive ? 'active' : ''}`} >
      <Subtitle style={{ color: COLOR_ACCENT }}>
        <FontAwesomeIcon icon={address ? "check" : "caret-right"} style={{ marginRight: '1rem' }} />1. Connect Ethereum Key{address ? ' - connected!' : ''}</Subtitle>
      { isActive &&
        <>
          <section>
            <p>This step will make sure that you are the owner of a public/private key pair. Web3 enabled browser required.</p>
          </section>
        </>
      }
      <div style={{ marginTop: '1rem' }}>
        {address
          ? <div>Your public Ethereum address is: <EthereumAddress address={address} /></div>
          : <Button onClick={handleConnect}>Connect</Button>
        }
      </div>
    </section>
  )
}

function StepConnectSocialMedia({ isActive, isLoading, userHasTweeted, onUserHasTweeted }) {

  const [signedStatement, setSignedStatement] = useState(undefined)

  const handleSignatureChanged = ({ signedStatement }) => {
    setSignedStatement(signedStatement)
  }

  const { connectedIdentities } = useAppContext()
  return (
    <section className={`Step ${isActive ? 'active' : ''}`} >
      {connectedIdentities.length > 0 || userHasTweeted
        ? <Subtitle style={{ color: COLOR_ACCENT }}><FontAwesomeIcon icon="check" style={{ marginRight: '1rem' }} />2. Link Key to Twitter - tweeted!</Subtitle>
        : (
          <Subtitle style={{
            color: isActive ? COLOR_ACCENT : '#999'
          }}>{isActive && <FontAwesomeIcon icon="caret-right" style={{ marginRight: '1rem' }} />}2. Link Key to Twitter</Subtitle>
        )
      }
      {connectedIdentities.length === 0 &&
      (
        <section>
          <p>This step makes sure you are who you are and that you own a specific Twitter profile.</p>
        </section>
      )
      }
      {isLoading
        ? <LoadingView placeholder={"Performing lookup..."} />
        : (
          <>
            {isActive && (
              <>
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '10rem' }}>
                  <div style={{ flex: 1, marginRight: '2rem' }}>
                    <ProofOfKeys channel={'Twitter'} onSignatureChanged={handleSignatureChanged} />
                    {signedStatement &&
                      <>
                        <TweetProof signedStatement={signedStatement} />
                      </>
                    }
                    <button className="button" style={{ marginTop: '1rem' }} onClick={onUserHasTweeted}>I've tweeted a proof and want to proceed to next step</button>
                  </div>

                </div>
              </>
            )}
            {connectedIdentities.length > 0 &&
              <div style={{ display: 'flex', alignItems: 'center' }}>Your proof url is: <pre style={{ padding: 4, marginLeft: 5 }}>{ connectedIdentities[0].attestation.proof.url }</pre></div>
            }
          </>
        )
      }
    </section>
  )
}

function StepVerifyTwitter({ isActive, isLoading }) {

  const { connectedIdentities } = useAppContext()

  return (
    <section className={`Step ${isActive ? 'active' : ''}`} >
      {connectedIdentities.length > 0
        ? <Subtitle style={{ color: COLOR_ACCENT }}><FontAwesomeIcon icon="check" style={{ marginRight: '1rem' }} />3. Verify Tweet - tweet found and verified!</Subtitle>
        : (
          <Subtitle style={{
            color: isActive ? COLOR_ACCENT : '#999'
          }}>{isActive && <FontAwesomeIcon icon="caret-right" style={{ marginRight: '1rem' }} />}3. Verify Tweet</Subtitle>
        )
      }
      {connectedIdentities.length === 0 &&
        <section>
          <p>This step verifies your tweeted proof.</p>
        </section>
      }
      {isLoading
        ? <LoadingView placeholder={"Performing lookup..."} />
        : (
          <>
            {isActive && (
              <>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ flex: 1, marginRight: '2rem' }}>
                    <TwitterPostVerification />
                  </div>
                </div>
              </>
            )}
            {connectedIdentities.length > 0 &&
              <div style={{ display: 'flex', alignItems: 'center' }}>Your Twitter username is: <pre style={{ padding: 4, marginLeft: 5 }}>{connectedIdentities[0].username}</pre></div>
            }
          </>
        )
      }
    </section>
  )
}


export default function Verification() {

  const { connectedAddress, notify, login } = useAppContext()
  const address = connectedAddress

  const [isSigning, setIsSigning] = useState(false)
  const [signature, setSignature] = useState(undefined)

  const match = useRouteMatch() || {}
  const { params = {} } = match
  const { service, jwt } = params

  const tabs = [/*'Twitter',*/ 'Discord' /*, 'General' */]

  const [selectedTab, setSelectedTab] = useState(service === 'discord' ? 'Discord' : tabs[0])

  // set in step 1
  // const { connectedAddress: address, login } = useAppContext();

  // set in step 2
  const { connectedIdentities, performingIdentityLookup } = useAppContext();

  const [userHasTweeted, setUserHasTweeted] = useState(false)

  function renderTwitterVerification() {
    return (
      <>
      <StepConnectEthereum isActive={address === undefined} handleConnect={login} address={address} />

      <StepConnectSocialMedia
        isActive={!!address && !userHasTweeted && connectedIdentities.length === 0}
        address={address}
        userHasTweeted={userHasTweeted}
        onUserHasTweeted={() => setUserHasTweeted(true)}
        isLoading={performingIdentityLookup}
      />

      <StepVerifyTwitter
        isActive={userHasTweeted && connectedIdentities.length === 0}
      />
      </>
    )
  }

  function renderGeneralVerification(tab) {

    const submitProof = (callbackUrl, proof) => {

      console.log('post proof to', callbackUrl)
      fetch(callbackUrl, {
        method: 'POST',
        body: JSON.stringify({
          proof
        })
      })
      .then(response => response.json())
      .then(data => {
        console.log('received data', data)
        if (data.status === 'ok') {
          notify('Verification complete!', 'success')
        } else {
          throw new Error('Verification failed')
        }
      })
      .catch(err => {
        console.log('error', err)
        notify('Verification Error!')
      })

    }

    const getTokenData = (jwt) => {
      const token = verifier.decodeChallengeToken(jwt)
      // 721 lib ChallengeData
      return {
        uuid: token.uuid,
        address: token.addr,
        channel: token.chan,
        callback: token.cbk
      }
    }

    async function handleSignMessage() {
      setIsSigning(true)
      setSignature(undefined)
      const { uuid, address, channel, callback } = getTokenData(jwt)
      const statement = verifier.createProofStatement(`${uuid}`, address, channel)
      try {
        const signature = await verifier.userSignMessage(statement)
        setSignature(signature)

        submitProof(callback, {
          jwt,
          signature
        })

      } catch (error) {
        // FIXME indicate error
      } finally {
        setIsSigning(false)
      }
    }

    function renderToken(jwt) {
      const { uuid, address, channel } = getTokenData(jwt)
      const statement = verifier.createProofStatement(`${uuid}`, address, channel)

      let addressMatching = false
      try {
        addressMatching = connectedAddress.toLowerCase() == address.toLowerCase()
      } catch (error) {
        
      }

      return (
        <div style={{
          padding: '2rem',
          border: `2px solid ${COLOR_PRIMARY}`, 
          borderRadius: '.5rem', 
          background: 'white'
        }}>
          <div style={{ fontSize: '1.5rem', fontWeight: 600, color: COLOR_ACCENT, marginBottom: '1rem' }}>Discord Proof Statement:</div>
          <textarea readOnly disabled value={statement} style={{ width: '100%', height: 100, padding: 5, background: '#f3f3f3' }} />

          {!connectedAddress
          ?  <Button onClick={login}>Connect</Button>
          : (
            signature
            ? (
              <>
              <div style={{ fontSize: '1.5rem', fontWeight: 600, color: COLOR_ACCENT, marginBottom: '1rem' }}>Signature:</div>
              <code style={{ padding: '.5rem'}}>{signature}</code>
              </>
            )
            : (
              <div style={{ marginTop: '1rem', padding: 5 }}>
                {!addressMatching && <div style={{ color: COLOR_ERROR, fontWeight: 900, fontSize: '1.1rem' }}>The provided address is not matching the connected user (switch account?): 
                <div> {address} != {connectedAddress} </div>
              </div> }
              <div style={{ marginTop: '1rem' }}>
                <Button enabled={addressMatching} loading={isSigning} style={{ background: 'white', padding: '1.5rem', fontSize: '1.15rem' }} onClick={handleSignMessage} >Sign the proof</Button>
              </div>
            </div>
            )
          )
          }
        </div>
      )
    }
    
    return (
      <div>
        {jwt
        ? renderToken(jwt)
        : (<>
            {tab == 'Discord' && <><span>Discord verification is initiated in the Discord app.</span> <br/></> }
            <span> No verification token found in URL.</span>
          </>
        )
        }
      </div>
    )
  }


  return (
    <PageContainer>
      <PageTitle>Verification Service <VerifiedIcon color /></PageTitle>

      <InfoBox>
        <p>No worries, this is not your average verification service.</p>
        <p><strong>You DON'T need:</strong> ID, bank statement, driver's license, utility bill, phone call, webcam... Instead, we use cryptography here.</p>
        <p>
        <span>The Verification Service will generate a certificate for your social media profile. 
          It serves as a proof that you own the profile and can be used independent of the platform to e.g. claim your username somewhere else. </span>
        <span>Moreover, you will be able to digitally sign content to prove its authenticity, 
          use your username as an alias for your Ethereum address to fight imposters and scammers and do a bunch of other really powerful things.</span>
        </p>
        <span><strong>Verification is automated and completely free. Have fun! </strong> {/* [<a href=""> Go to guide</a>] */} </span>
      </InfoBox>

      <Tabs tabs={tabs} active={selectedTab} style={{ overflow: 'none' }} onTabChanged={tab => setSelectedTab(tab)} />

      {selectedTab === 'Twitter'
      ? renderTwitterVerification()
      : renderGeneralVerification(selectedTab) 
      }

    </PageContainer>
  )
}
