import React from 'react'
import Spinner from './Spinner'

export default function LoadingView({ placeholder = 'Loading data...' }) {
  return (
    <div style={{ padding: '4rem', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <span>{placeholder}</span>
      <Spinner style={{ fontSize: '5rem' }} /> 
    </div>
  )
}
