import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export default function SocialLink({ url, name }) {
  return (
    <a className="button" target="_blank" rel="noopener noreferrer"  href={url} style={{ backgroundColor: 'transparent', border: 'none', fontSize: '1.2rem' }}>
      <FontAwesomeIcon icon={['fab', name.toLowerCase()]} style={{ marginRight: 5 }} />{name}
    </a>
  )
}
