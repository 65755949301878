import React, { useState } from 'react'
import { isDev } from '../../libs/utils'
import Button from '../Button'
import CopyClipboardButton from '../CopyClipboardButton'
import Field from '../Field'
import Panel from '../Panel'
import Step from '../Step'
import TwitterShareButton from '../TwitterShareButton'

export default function TweetProof({ signedStatement }) {

  const [signedStatementWithUrl] = useState(`${signedStatement} 721Fans.com`)

  const [fakeTweetId, setFakeTweetId] = useState('')


  async function handleTweetDev() {
    // let fakeTweetId = await api.makeTweet(signedStatementWithUrl)
    //setFakeTweetId(fakeTweetId.replace("\"", '').replace("\"", ''))
  }

  // https://developer.twitter.com/en/docs/counting-characters
  // URLs: All URLs are wrapped in t.co links. 
  // The current length of a URL in a Tweet is 23 characters, even if the length of the URL would normally be shorter.
  const TWITTER_URL = 23 + 1 // (space) // + ' 721Fans.com'.length

  return (
    <Panel style={{ paddingTop: '0.5rem' }}>
      <Step>Tweet this proof</Step>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ marginTop: '1rem', marginBottom: '1rem' }}>Do <strong>NOT</strong> modify the tweet's content. If the username does not match the profile where the proof is posted the verification will fail ;-)</span>
      </div>
      <textarea readOnly disabled value={signedStatementWithUrl} style={{ width: '100%', height: 100, padding: 5 }} />
      <div style={{ display: 'flex' }}>
        <div style={{ marginLeft: 'auto' }}><span>{signedStatement.length + TWITTER_URL }/280</span></div>
      </div>
      {isDev() && false /* deactivated */ &&
        <div style={{
          padding: 10,
          border: '1px solid blue',
          marginBottom: '1.5rem',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <div>Dev mode detected: press "Tweet Dev" to simulate a tweet. Copy the fake tweet id afterwards to verify in next step</div>
          <div style={{ display: 'flex', marginTop: 5 }}><Button style={{ marginRight: '1rem' }} onClick={handleTweetDev}>Tweet Dev</Button> <Field readonly value={fakeTweetId} style={{ margin: 0}}/></div>
        </div>
      }
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', flexDirection: 'column'}}>
          <CopyClipboardButton clipboardText={signedStatementWithUrl} />
        </div>
        <TwitterShareButton text={signedStatement} url={'721Fans.com'} />
      </div>
    </Panel>
  )
}
