import React, { Component } from 'react'

export default class TwitterEmbeddedTweet extends Component {
  state = {
    isLoading: true
  }
  constructor(props) {
    super(props)
    this.embedContainer = React.createRef();
  }
  componentDidMount = () => {
    window.twttr.widgets.createTweet(
      this.props.tweetId,
      this.embedContainer.current,
      this.props.options
    ).then(() => this.setState({ isLoading: false }))
  }
  render() {
    const { isLoading } = this.state
    return (
      <div>
        {isLoading && <span>Loading tweet...</span>}
        <div ref={this.embedContainer} />
      </div>
    )
  }
}
