export const COLOR_SUCCESS = '#4ea579'

export const MUTED_TEXT = '#999'


// insta blue #3799EF

let blue = '#080A52'
let pink = '#EB2188'

// https://looka.com/blog/logo-color-combinations/
let ob = {
  blue: '#141a46',
  orange: '#ec8b5e'
}

let bt = {
  blue: '#243665',
  turq: '#8bd8bd'
}

let pb = {
  pink: '#fbeaeb',
  blue: '#2f3c7e'
}

let op = {
  orange: '#F0A07C',
  purple: '#4A274F'
}

export const COLOR_PRIMARY = '#EC8B5E'
export const COLOR_ACCENT = '#141A46'

export const COLOR_ERROR = 'rgb(255 98 98)'