import React from 'react'
import Button from './Button';
import { useAppContext } from '../libs/contextLib';

export default function CopyClipboardButton({ clipboardText, style, enabled }) {

  const { notify } = useAppContext()

  function handleCopyToClipboard(value) {
    try {
      navigator.clipboard.writeText(value).then(function () {
        // console.log('Async: Copying to clipboard was successful!');
        // setIsCopied(value)
        notify('Copied to clipboard!', 'dark')
      }, function (err) {
        console.error('Async: Could not copy text: ', err);
        notify('Failed to copy', 'error')
      });
    } catch (error) {
      
    }
  }

  return (
    <Button onClick={ () => handleCopyToClipboard(clipboardText) } style={{ marginRight: '1rem', ...style }} enabled={enabled} >Copy Clipboard</Button>
  )
}
