import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Spinner({ style }) {
  return (
    <span style={style}>
      <FontAwesomeIcon icon="spinner" spin />
    </span>
  )
}
