import React from 'react'
import Button from './components/Button';
import EthereumAddress from './components/EthereumAddress';

import { useAppContext } from './libs/contextLib';
import {
  NavLink
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIsMobile } from './hooks/hooksLib';
import VerifiedIcon from './components/VerifiedIcon';
import { COLOR_ACCENT, COLOR_PRIMARY } from './libs/colors';


function NavbarItem({ item }) {
  return (
    <NavLink
      style={{
        color: '#243665',
        fontWeight: 600,
        letterSpacing: 1.12
      }} 
     to={item.to} className="NavItem" activeClassName='is-active'>
      <span>
        {item.label}
      </span>
    </NavLink>
  )
}


export default function Navbar() {

  const { connectedAddress, login, notify } = useAppContext()
  const { isMobile } = useIsMobile()

  const navItems = [
    // { to: '/debug', label: 'Debug' },
    // { to: '/create', label: 'Create', icon: "rocket" },
    // { to: '/cards', label: 'Cards', icon: "portrait" },
    // { to: '/create', label: 'Create', icon: "rocket" },
    // { to: '/collect', label: 'Collect', icon: "search-dollar" },
    { to: '/verification', label: 'Verification', icon: "certificate" },
    { to: '/wallet', label: 'Wallet', icon: "wallet" },
    { to: '/about', label: 'About', icon: "book-open" },
    // { to: '/login', label: 'Login' },
  ]

  function handleCopyToClipboard(value) {
    try {
      navigator.clipboard.writeText(value).then(function () {
        // console.log('Async: Copying to clipboard was successful!');
        // setIsCopied(value)
        notify(`Copied to clipboard!\n${value}`, 'dark')
      }, function (err) {
        console.error('Async: Could not copy text: ', err);
        notify('Failed to copy', 'error')
      });
    } catch (error) {
      
    }
  }

  const renderNavbarTop = () => {
    return (
      <nav className="navbar Navbar" role="navigation" aria-label="main navigation" style={{ display: 'flex', fontSize: '1.15rem', padding: 10 }}>
        <div style={{ fontSize: '2rem'}}>721<span style={{ fontWeight: 600 }}>Fans</span></div>
        <div style={{ marginLeft: 'auto'}}>
          <ul style={{ display: 'flex', margin: 5, alignItems: 'center' }}>
            {navItems.map(item => <NavbarItem key={item.to} item={item} />)}
          {connectedAddress
            ? (
              <div style={{ fontSize: '1rem', background: COLOR_PRIMARY, padding: '.5rem', paddingLeft: '1rem', paddingRight: '1rem', fontWeight: 600, color: COLOR_ACCENT, borderRadius: '.5rem', cursor: 'pointer' }}>
                <div onClick={() => handleCopyToClipboard(connectedAddress)}>
                  <EthereumAddress address={connectedAddress} short={true} code={false} />
                </div>
              </div>
            ) 
            : <Button style={{ fontSize: '1.1rem' }} onClick={login}>Connect</Button>
          }
          </ul>
        </div>
      </nav>
    )
  }

  const renderNavbarBottom = () => {
    return (
      <nav style={{
        padding: 10,
        fontSize: '1.75rem',
        display: 'flex',
        alignItems: 'center',
        background: '#f3f3f3',
        borderTop: '1px solid #b7b7b7'
      }} className="navbar is-fixed-bottom" role="navigation" aria-label="dropdown navigation">
        <div style={{ marginRight: '2rem' }}>721<span style={{ fontWeight: 600 }}>Fans</span></div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            flex: 1,
          }}>
          {navItems.map(item => (
            <NavLink
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
              to={item.to}
              className="NavItem"
              activeClassName='is-active-mobile'>
              {item.icon === 'certificate'
              ? <VerifiedIcon />
              : <FontAwesomeIcon style={{ fontWeight: 600 }} icon={item.icon} />
              }
              <div style={{ fontSize: '0.8rem', paddingTop: 3 }}>{item.label}</div>
            </NavLink>
          ))}
        </div>

      </nav>
    )

  }

  return (
    isMobile
      ? renderNavbarBottom()
      : renderNavbarTop()
  )
}
