import React, { Component } from 'react'
import ExternalLink from '../components/ExternalLink'
import PageContainer from '../components/PageContainer'
import PageTitle from '../components/PageTitle'

export default class About extends Component {
  render() {
    return (
      <PageContainer>

        <PageTitle>About</PageTitle>
        <div style={{ marginBottom: 20 }}>
          721Fans is a project exploring new ways to empower artists, content creators and communities using tokenized social media and cryptography.
          We help them getting onboarded to the Ethereum ecosystem by using cutting edge technologies such as meta transactions, on-chain escrow & identity, NFT token farming and Keybase-like social identity proofs. 
          Our goal is to provide creatives with the best possible tools to leverage the power of this exciting new technology.
          <br />
          <br />
          <p>More information and documentation for integrations will be released soon.</p>

          <br />
          <p>In the meantime you can connect with us on these channels:</p>
          <div style={{ marginTop: '2rem'}} >
          <ExternalLink url="https://twitter.com/721fans" name="Twitter" />
          <ExternalLink url="https://medium.com/@721fans" name="Medium" />
          <ExternalLink url="https://discord.gg/a6gP7Fxk" name="Discord" />
          <ExternalLink url="https://github.com/721Fans" name="GitHub" />
          </div>
        </div>
      </PageContainer>
    )
  }
}
