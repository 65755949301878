import React from 'react'
import { MUTED_TEXT } from '../libs/colors'

export default function Field({ value, readonly = false, placeholder, onChange, enabled=true, label, suffix, fieldStyle , style, hasAddon = false}) {
  return (
    <div style={{ ...style }}>
      <label className="label" style={{ color: MUTED_TEXT }}>{label}</label>
      <div className={`field ${suffix ? 'has-addons' : ''} ${hasAddon ? 'has-addons' : ''}`} style={{ marginBottom: '1rem', marginTop: '1rem', ...style }}>
        {hasAddon && 
          <p className="control">
            <button className="button is-static">
              ETH
            </button>
          </p>
        }
        <p className="control">
        <input 
          className="input" 
          style={{...fieldStyle}}
          readOnly={readonly} 
          placeholder={placeholder}
          type="text" 
          value={value} 
          disabled={!enabled}
          onChange={onChange} 
        />
        </p>
        {suffix && (
        <p className="control" style={{
          width: '8rem'
        }}>
          <button className="button is-static">
            {suffix}
          </button>
        </p>
        )}
      </div>
    </div>
  )
}
