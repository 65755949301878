import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../libs/contextLib'
import Button from '../Button'
import Field from '../Field'
import Panel from '../Panel'
import Step from '../Step'
import { verifier } from '721-fans-lib'

export default function ProofOfKeys({ channel, onSignatureChanged = () => { } }) {

  const { connectedAddress: address } = useAppContext();

  const [textInput, setTextInput] = useState('')
  const [statement, setStatement] = useState('')
  const [signedStatement, setSignedStatement] = useState(undefined)

  const handleSignMessage = async () => {
    const signature = await verifier.userSignMessage(statement)
    // TODO if valid
    // space important for parser
    const signedStatement = `${statement} \n\n${signature}`
    setSignedStatement(signedStatement)
    onSignatureChanged({ signature, signedStatement })
  }

  const handleUsernameChanged = (username) => {
    if (signedStatement) {
      setSignedStatement(undefined)
      // reset in parent
      onSignatureChanged({ signature: undefined, signedStatement: undefined })
    }
    if (username.startsWith('@')) {
      username = username.substring(1)
    }
    const statement = verifier.createProofStatement(username, address, channel)
    setTextInput(username)
    if (!username) {
      setStatement('')
    } else {
      setStatement(statement)
    }
  }

  const TWITTER_URL = 23 + 1 // (space) // + ' 721Fans.com'.length
  const SIGNATURE_LENGTH = 132

  const tweetLength = (signedStatement ? signedStatement.length : (statement.length + 3 + SIGNATURE_LENGTH)) + TWITTER_URL

  return (
    <Panel>
      <Step>Generate</Step>
      <Field value={textInput} placeholder="Enter your Twitter username" onChange={e => handleUsernameChanged(e.target.value)} label="" />
      {!signedStatement && textInput &&
        <>
          <span>Tweet Proof Statement:</span>
          <textarea readOnly disabled value={statement} style={{ width: '100%', height: 100, padding: 5 }} />
          <div style={{ display: 'flex', color: tweetLength > 280 ? 'red' : 'inherit' }}>
            <div style={{ marginLeft: 'auto' }}><span>{tweetLength}/280</span></div>
          </div>
          <Button enabled={textInput.length > 0 && tweetLength <= 280} onClick={handleSignMessage} >Sign this tweet</Button>
        </>
      }
    </Panel>
  )
}
