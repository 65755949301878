import React from 'react'
import { Redirect } from 'react-router-dom'

export default function Landing() {
  return (
    <div>
      <h1>Welcome</h1>
      <Redirect to="verification"/>
    </div>
  )
}
