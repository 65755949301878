import React, { useState, useEffect } from 'react'
import { COLOR_ACCENT } from '../../libs/colors'
import { useAppContext } from '../../libs/contextLib'
import Button from '../Button'
import CopyClipboardButton from '../CopyClipboardButton'
import Field from '../Field'
import { isValidEthereumAddress, FAN, ethers, getxDaiProvider } from '721-fans-lib'
import { server } from '../../config'

export default function Transfer() {

  const [transferAmount, setTransferAmount] = useState(10)
  const [addressOrUsername, setAddressOrUsername] = useState(undefined)
  const [botCommand, setBotCommand] = useState('')
  const [resolvedAddress, setResolvedAddress] = useState(undefined)

  const [txPending, setTxPending] = useState(false)

  const { notify, connectedAddress } = useAppContext()

  const [validTx, setValidTx] = useState(false)

  function handleReceiverChanged(e) {
    // setTweetUrl(newUrl)
    // setTweetId(undefined)
    // parseTweetId(newUrl, setTweetId)
    let _addressOrUsername = e.target.value
    setValidTx(false)
    /*
    console.log('receiver changed')
    setAddressOrUsername(_addressOrUsername)
    setBotCommand(`@721Fans send ${transferAmount} $FAN to ${_addressOrUsername}`)
    resolveAddress()
    */
    if (isValidEthereumAddress(_addressOrUsername)) {
      setResolvedAddress(_addressOrUsername)
      setValidTx(true)
    }
  }

  function handleTransfer() {
    setTxPending(true)
    const from = connectedAddress
    const to = resolvedAddress
    const amountFan = `${transferAmount}`
    const relayer = `${server.url}/relayer`
    FAN.transferToAddressMeta(from, to, amountFan, relayer)
    .then(receipt => {
      console.log('receipt', receipt)
      notify(`Tx Confirmed\n${receipt.transactionHash}`, 'dark')
      setTxPending(false)
    })
    .catch(err => {
      console.log('meta tx failed', err)
      notify('Failed: '+err.message)
      setTxPending(false)
    })
  }

  return (
    <div style={{ 
      marginBottom: '1rem',
      marginTop: '1rem',
      padding: '2rem',
      background: 'white',
      border: `2px solid ${COLOR_ACCENT}`, 
      borderRadius: '1rem', 
    }}>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem', flexWrap: 'wrap' }}>
        <Field label="Amount" value={transferAmount} onChange={e => !isNaN(e.target.value) && setTransferAmount(e.target.value)} suffix="FAN" />
        <Field style={{  }} label="To" placeholder="address" value={addressOrUsername} onChange={handleReceiverChanged} />
        {/* <Spinner style={{ fontSize: '1.5rem', paddingTop: '0.5rem' }} /> */}
        <Button loading={txPending} style={{ marginLeft: '1rem', marginTop: '1.5rem' }} enabled={validTx} onClick={handleTransfer}>Transfer</Button>
      </div>
      <div style={{ display: 'none', alignItems: 'center', visibility: 'hidden' }}>
        <Field label="Send via tweet (bot)" value={botCommand} readonly fieldStyle={{ flex: 1 }} style={{ flex: 1 }} />
        <CopyClipboardButton clipboardText={botCommand} style={{ marginTop: '1.5rem', marginLeft: '1rem', marginRight: 0 }} enabled={validTx} />
      </div>
    </div>
  )
}