import { FAN } from '721-fans-lib/dist/contracts'
import React, { useEffect, useState } from 'react'
import { COLOR_ACCENT } from '../../libs/colors'
import { useAppContext } from '../../libs/contextLib'
import Button from '../Button'
import Field from '../Field'
import Spinner from '../Spinner'
import { ethers } from 'ethers'
import { server } from '../../config'

export default function Bot() {

  const { connectedAddress } = useAppContext()
  const [allowance, setAllowance] = useState(100)
  const [currentAllowance, setCurrentAllowance] = useState(0)
  const [fetchingAllowance, setFetchingAllowance] = useState(false)
  const [txPending, setTxPending] = useState(false)

  const updateAllowance = () => {
    setFetchingAllowance(true)
    FAN.getAllowance(connectedAddress)
    .then(allowance => {
      setCurrentAllowance(allowance)
      setFetchingAllowance(false)
    })
    .catch(err => {
      setFetchingAllowance(false)
    })
  }

  useEffect(() => {
    updateAllowance()
    return () => {
      // cleanup
    }
  }, [connectedAddress])

  async function handleApprove() {
    const relayer = `${server.url}/relayer`
    setTxPending(true)
    FAN.approveBot(connectedAddress, allowance, relayer)
      .then(data => {
        console.log('received data from relayer', data)
        setTxPending(false)
        return updateAllowance()
      })
      .catch(err => {
        console.log('meta tx signing failed', err)
        setTxPending(false)
      })
  }

  return (
    <div style={{ 
      marginBottom: '1rem',
      marginTop: '1rem',
      padding: '2rem',
      background: 'white',
      border: `2px solid ${COLOR_ACCENT}`, 
      borderRadius: '1rem', 
    }}>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem', flexWrap: 'wrap' }}>
        <Field label="Amount" value={allowance} style={{ flex: 1}} onChange={e => !isNaN(e.target.value) && setAllowance(e.target.value)} suffix="FAN" />
        <Button loading={txPending} style={{ marginLeft: '1rem', marginTop: '1.5rem' }} enabled={connectedAddress && true} onClick={handleApprove}>Approve</Button>
      </div>
      <div>
        <span style={{ marginRight: 5}}>Current allowance: </span>
      {fetchingAllowance
      ? <Spinner />
      : <span>{ethers.utils.commify(currentAllowance)}</span> 
      }
      </div>
    </div>
  ) 
}
