import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { COLOR_ACCENT, COLOR_PRIMARY } from '../libs/colors'

export default function VerifiedIcon({ color = false }) {
  return (
    <span className="fa-layers fa-fw" style={{
      verticalAlign: 'middle',
      display: 'inline-block'
    }}>
      <FontAwesomeIcon icon="certificate" color={color ? COLOR_PRIMARY : undefined}  />
      <FontAwesomeIcon icon="check" inverse={color ? false : true} color={color ? COLOR_ACCENT : undefined} transform="shrink-6"/>
    </span>
  )
}
