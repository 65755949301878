import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { COLOR_ACCENT, COLOR_PRIMARY } from '../libs/colors'

export default class Button extends Component {
  render() {
    const { enabled = true, loading = false, style, className = '', action } = this.props
    return (
      <button
        className={`button ${className} ${action ? 'action-button' : ''}`}
        style={{ /*alignSelf: 'flex-start',*/ background: action ? undefined : COLOR_PRIMARY, borderRadius: '.5rem', color: COLOR_ACCENT, fontWeight: 700, ...style }}
        disabled={loading || enabled === false} 
        onClick={this.props.onClick}>
        {loading && <FontAwesomeIcon  icon="spinner" spin style={{ marginRight: 5 }} />}
        {this.props.children}
      </button>
    )
  }
}
