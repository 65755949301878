import { FAN } from '721-fans-lib'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useAppContext } from '../libs/contextLib'

export default function useBalance() {

  const [balance, setBalance] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const { connectedAddress } = useAppContext()

  const updateBalance = () => {
    setIsLoading(true)
    FAN.getBalanceDisplay(connectedAddress)
    .then(balance => {
      setBalance(balance)
      setIsLoading(false)
    })
    .catch(e => {
      console.log(e)
      setIsLoading(false)
      // setLoadingBalance(false)
    })
  }

  useEffect(() => {
    if (!connectedAddress) {
      setIsLoading(false)
      return
    }

    updateBalance()

    let disposable = undefined

    FAN.subscribeBalanceChanges(connectedAddress, (from, to, amount) => {
      // console.log('received balance change', from, to, amount)
      updateBalance()
    })
    .then(_disposable => {
      disposable = _disposable
    })

    return () => {
      // cleanup
      if (disposable) {
        disposable.dispose()
      }
    }
  }, [connectedAddress])

  return (
    {
      balance,
      isLoading
    }
  )
}
