import { useEffect, useState } from "react";

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);
  return [fields,
    function (event) {
      setValues({
        ...fields,
        [event.target.id]: event.target.value
      });
    }
  ];
}

export function useIsMobile() {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500)

  const handleWindowResize = () => {
    if(window.innerWidth <= 500) {
      if (!isMobile) {
        setIsMobile(true)
      }
    } else {
      if (isMobile) {
        setIsMobile(false)
      }
    }
  }

  window.addEventListener("resize", handleWindowResize);

  /*
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    // return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  */
 
  return {
    isMobile
  }
}