import React from 'react'

import {
  Switch,
  Route,
} from "react-router-dom";

import About from './pages/About';
// import Cards from './pages/Cards';
// import Factory from './pages/Factory';
import Create from './pages/Create';
// import Explore from './pages/Explorer';
// import AuctionDetails from './pages/AuctionDetails';
// import Debug from './pages/Debug';
import NotFound from './pages/NotFound';
// import PendingTxDetails from './pages/PendingTxDetails';
import Landing from './pages/Landing';
import Verification from './pages/Verification';
import Wallet from './pages/Wallet';

export default function Routes() {
  return (
    <Switch>
      <Route path="/create">
        <Create />
      </Route>
      {/*

      <Route path="/collect/pending/details/:pendingTxData" component={PendingTxDetails} />
      <Route path="/collect/auctions/:auctionId" component={AuctionDetails} />
      <Route path="/collect">
        <Explore />
      </Route>

      <Route path="/debug">
        <Debug />
      </Route>
      <Route path="/cards">
        <Cards />
      </Route>
      */}
      <Route path="/wallet">
        <Wallet />
      </Route>
      <Route path="/about">
        <About />
      </Route>
      <Route path="/verification/:service?/:jwt?">
        <Verification />
      </Route>
      <Route exact path="/">
        <Landing />
      </Route>
      {/* Finally, catch all unmatched routes */} 
      <Route>
        <NotFound /> 
      </Route>
 
    </Switch>
  )
}
