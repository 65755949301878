import React from 'react'
import { COLOR_ACCENT, COLOR_PRIMARY } from '../libs/colors'

export default function InfoBox({ children }) {
  return (
    <div style={{ border: '2px dashed '+COLOR_ACCENT, borderRadius: 5, padding: '1rem', marginBlock: '.5rem', marginBottom: '1rem' }}>
      {children}
    </div>
  )
}
