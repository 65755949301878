import React from 'react'

const shortAddress = (address, length = 4) => [
  ...address.split('').slice(0, length+2), // + 0x
  '...',
  ...address.split('').slice(42 - length)
].join('')

export default function EthereumAddress({ address, short = false, code = true }) {
  if (!address) {
    return <span>Invalid</span>
  }
  let _address = short ? shortAddress(address) : address
  return code ? <code>{_address}</code> : <span>{_address}</span>
}
