import React, { useEffect, useState } from 'react'
import './App.css';

import { AppContext } from "./libs/contextLib";
import Navbar from './Navbar';
import Routes from './Routes';
import { getUserAddress } from '721-fans-lib';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// TODO make available as app context
const notify = (msg, type = 'error', options) => toast[type](msg, {
  position: "top-right",
  autoClose: 3500,
  hideProgressBar: true,
  style: {
    fontWeight: 'bold'
  },
  ...options
});

export default function App() {

  const [connectedAddress, setConnectedAddress] = useState(undefined)
  const [connectedIdentities, setConnectedIdentities] = useState([])
  const [performingIdentityLookup, setPerformingIdentityLookup] = useState(true)

  // start with false: we only fetch if there is an address
  const [fetchingTokenMetadata, setFetchingTokenMetadata] = useState(false)

  const [ownedTokens, setOwnedTokens] = useState([])
  // const { network } = useNetwork()

  // if still / already connected try to login
  useEffect(() => {
    const tryConnect = async () => {
      try {
        const connected = window.ethereum.isConnected()
        login()
      } catch (error) {
        console.log('failed')
      }
    }

    tryConnect()

    return () => {
      // cleanup
    }
  }, [])


  useEffect(() => {
    try {
      window.ethereum.on('accountsChanged', function (accounts) {
        // Time to reload your interface with accounts[0]!
        // logout
        setConnectedAddress(undefined)
      })
    } catch (error) {
      
    }
    return () => {
      // cleanup
    }
  }, [])

  /*
   useEffect(() => {
    const fetchTokenMetadata = async (address) => {
      setFetchingTokenMetadata(true)
      try {
        const tokens = await getOwnedTokensWithMetadata(address)
        setOwnedTokens(tokens)
      } catch (error) {
        notify('Failed to fetch tokens')
        console.log('error', error)
      } finally {
        setFetchingTokenMetadata(false)
      }
    }
    if (connectedAddress) {
      fetchTokenMetadata(connectedAddress)
    }
    return () => {
      // cleanup
    }
  }, [connectedAddress])
  */

   // if the address changes - try to find an associated identity


  function login() {
    getUserAddress()
    .then(address => {
      setConnectedAddress(address)
    })
    .catch(error => {
      notify('Connect to Web3 failed! Metamask installed, unlocked?')
      console.log('Login failed', error)
    })
  }

  return (
    <div className={"App " + 'has-navbar-fixed-bottom' }>

      <AppContext.Provider value={{ 
        connectedAddress, 
        setConnectedAddress, 
        login, 
        notify
      }}>
        <Navbar />
      </AppContext.Provider>

      <main
        className="Main"
        style={{
          display: 'flex',
          padding: 0,
          margin: 0,
          flex: 1,
          flexDirection: 'column',
          height: '100%',
          paddingBottom: 50,
          maxWidth: 1080,
          margin: 'auto'
        }}>
          <AppContext.Provider value={{
            connectedAddress, 
            setConnectedAddress, 
            login, 
            connectedIdentities,
            setConnectedIdentities,
            performingIdentityLookup,
            fetchingTokenMetadata,
            ownedTokens,
            notify 
          }}>
            <Routes />
          </AppContext.Provider>
      </main>
      <ToastContainer />
    </div>
  );
}