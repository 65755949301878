import React, { Component } from 'react'

export default class TwitterShareButton extends Component {
  state = {
    isLoading: true
  }
  constructor(props) {
    super(props)
    this.embedContainer = React.createRef();
  }
  componentDidMount = async () => {
    if (!window.twttr) {
      console.log('subscribe')
      if (!window.temit) {
        return
      }
      window.temit.addEventListener('ready', (e) => {
        console.log('received twitter ready', e);
      })
      return
    }
    window.twttr.widgets.createShareButton(
      this.props.url,
      this.embedContainer.current,
      {
        text: this.props.text,
        size: 'large',
        dnt: false
      }
    ).then(() => this.setState({ isLoading: false }))

  }
  render() {
    const { isLoading } = this.state
    const { style = {} } = this.props
    return (
      <div style={{
        ...style
       }}>
        {isLoading && <span>Loading tweet button...</span>}
        <div ref={this.embedContainer} />
      </div>
    )
  }
}
