import React from 'react'
import { COLOR_ACCENT, COLOR_PRIMARY } from '../../libs/colors'
import Spinner from '../Spinner'
import useBalance from '../../hooks/useBalance'


export default function BalanceCard({ token, account: address, symbol = 'FAN' }) {


  const { balance, isLoading } = useBalance()

  return (
    <div style={{ 
      display: 'flex',
      fontSize: '2rem',
      marginBottom: '1rem',
      background: 'white', 
      color: '#243665', 
      padding: '2rem', 
      fontFamily: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
      border: `2px solid ${COLOR_ACCENT}`, 
      borderRadius: '1rem', 
      fontWeight: 400
     }}>
      <span>
        {isLoading && <Spinner />}
        {!isLoading &&  balance} 
      </span>
      <span style={{ marginLeft: 'auto' }}>{symbol}</span>
    </div>
  )
}